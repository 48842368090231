// eslint-disable-next-line import/no-cycle
import { UserRole } from '../helpers/authHelper';

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyB829JrCZ0FQyWrbyCgatnLM7xb1T7fh3M',
  authDomain: 'fetch-19.firebaseapp.com',
  projectId: 'fetch-19',
  storageBucket: 'fetch-19.appspot.com',
  messagingSenderId: '218180445597',
  appId: '1:218180445597:web:c3aa2ea2672001052e7b74',
  measurementId: 'G-YXPP3XW03V',
};

//TODO you need to set defaultValue to false after connect to api
const defaultValue = true;
export const defaultPermissions = {
  //dashboard permissions
  Dashboard: defaultValue,

  //employees permissions
  Employees: defaultValue,
  'See employees': defaultValue,
  'See employees profiles': defaultValue,
  'Add employees': defaultValue,
  'Modify employees': defaultValue,
  'Delete employees': defaultValue,
  'See roles': defaultValue,
  'Add roles': defaultValue,
  'Modify roles': defaultValue,
  'See employees reports': defaultValue,
  'Reply employees reports': defaultValue,

  //clients permissions
  clients: defaultValue,
  'See clients list': defaultValue,
  'See clients profiles': defaultValue,
  'See clients reports': defaultValue,

  //posts permissions
  Posts: defaultValue,
  'See posts list': defaultValue,
  'Take action on posts': defaultValue,
  'See needs list': defaultValue,
  'See offers list': defaultValue,
  'See posts statistics': defaultValue,
  'See posts reports': defaultValue,
  // - categories
  'See categories list': defaultValue,
  'Add categories': defaultValue,
  'Modify categories': defaultValue,
  'Delete categories': defaultValue,
  // - specifications
  'See specifications list': defaultValue,
  'Add specifications': defaultValue,
  'Modify specifications': defaultValue,
  'Delete specifications': defaultValue,

  //notifications permissions
  Notifications: defaultValue,
  'See notifications list': defaultValue,
  'Add notifications': defaultValue,
  'Modify notifications': defaultValue,
  'Delete notifications': defaultValue,

  //advertisements permissions
  Advertisements: defaultValue,
  'See advertisements list': defaultValue,
  'Add advertisements': defaultValue,
  'Modify advertisements': defaultValue,
  'Delete advertisements': defaultValue,
  'See advertisements statistics': defaultValue,

  //FAQs permissions
  FAQs: defaultValue,
  'See FAQs list': defaultValue,
  'Add FAQs': defaultValue,
  'Modify FAQs': defaultValue,
  'Delete FAQs': defaultValue,

  //support permissions
  support: defaultValue,
  'See support requests list': defaultValue,
  'Reply support request': defaultValue,
  'Modify support request reply': defaultValue,

  //settings permissions
  settings: defaultValue,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Admin User',
  img: '/assets/img/profiles/l-2.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
