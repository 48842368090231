let name = 'fetch_admin_jwt=';

function getJWT() {
  /* let x = document.cookie;
  console.log(x); */
  /* let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  } */
  const token = localStorage.getItem(name);

  return token || '';
}

function setJWT(jwt) {
  /* const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = d.toUTCString();
  return `fetch_admin_jwt=${jwt}; expires=${expires}; path=/;`; */
  localStorage.setItem(name, jwt);
}

export { getJWT, setJWT };
