import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <Provider store={configureStore()}>
    <ApolloProvider client={client}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </ApolloProvider>
  </Provider>,

  document.getElementById('root')
);
