import { SET_ROLE_PERMISSIONS } from '../actions';

import { defaultPermissions } from '../../constants/defaultValues';

export default (state = defaultPermissions, action) => {
  switch (action.type) {
    case SET_ROLE_PERMISSIONS:
      return { ...action.payload };

    default:
      return { ...state };
  }
};
