import { NotificationManager } from '../components/common/react-notifications';
import { NOTIFICATION_TYPE } from '../constants/constants';

function triggerNotification(type, title, message) {
  type === NOTIFICATION_TYPE.ERROR
    ? NotificationManager.error(message, title, 3000, null, null, '')
    : type === NOTIFICATION_TYPE.SUCCESS
    ? NotificationManager.success(message, title, 3000, null, null, '')
    : NotificationManager.warning(message, title, 3000, null, null, '');
}

export default triggerNotification;
