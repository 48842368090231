export const FILTERS = {
  LIST: 0,
  TEXT: 1,
  LIST_LEN: 2,
  TAB: 3,
  MINMAX: 4,
};

export const FLOAT = { LEFT: 0, RIGHT: 1 };

export const LIST_ITEMS_TYPES = {
  LINK: 0,
  TEXT: 1,
  BADGE: 2,
  DATE: 3,
  COMPONENT: 4,
};

export const LIST_VARIATIONS = {
  IMAGE: 'imagelist',
  DATA: 'datalist',
  THUMB: 'thumblist',
};

export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  DATE_TIME: 'date-time',
  FILE: 'file',
  TEXT_LIST: 'text-list',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  EMAIL: 'email',
  PW: 'password',
  SEPARATOR: 'separator',
};
export const LIST_ITEM_ACTION_TYPES = {
  LIST: 0,
  SELECT: 1,
};

export const ACTION_BUTTON_TYPES = {
  LIST: 0,
  BTN: 1,
};

export const POSTS_TYPES = {
  OFFER: 'Offers',
  NEED: 'Needs',
};

export const NEED_TABS = {
  PROPOSITIONS: 'Propositions',
  REPORTS: 'Reports',
  // COMMENTS: 'Comments',
};

export const OFFER_TABS = {
  GALLERY: 'Gallery',
  COMMENTS: 'Comments',
  REPORTS: 'Reports',
};

export const NOTIFICATION_TYPE = {
  ERROR: 0,
  SUCCESS: 1,
  WARNING: 2,
};

export const RESPONSES_CODES = {
  ERROR: 404,
  SUCCESS: 201,
  PARAMS_ERROR: 400,
  UNAUTH: 401,
};

export const PERMISSIONS = {
  'See Roles List': 'acl/roles.read',
  'Create Employees': 'employees/admins.create',
  'Read Employees': 'employees/admins.read',
  'Update Employee': 'employees/admins.update',
  'Delete Employee': 'employees/admins.delete',
};
