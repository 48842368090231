import { ApolloClient, InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';
import { getJWT } from './helpers/cookieJWT';

var token = getJWT();

const client = new ApolloClient({
  headers: {
    authorization: token ? `Bearer ${token}` : '',
  },
  uri: process.env.REACT_APP_HASURA_URL,
  cache: new InMemoryCache(),
});
export const LAUNCH_TILE_DATA = gql`
  fragment LaunchTile on Launch {
    __typename
    id
    isBooked
    rocket {
      id
      name
    }
    mission {
      name
      missionPatch
    }
  }
`;

export default client;
